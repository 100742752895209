<template>
  <div class="vipMain">
    <div class="backHome">
      <span class="backIco" @click="back('prev')"></span>
      <span class="backHomeIco" @click="back('main')"></span>
      {{currentLang.title}}
    </div>
    <!-- 剩余金额 或者押金部分 -->
    <div class="container flex f20 flex__v flex__middle flex__center">
      <div>{{amount}}</div>
      <div>{{currentLang.amount}}</div>
    </div>
    <div class="flex flex__center mt-52 containerbox">
      <div>-—</div>
      <div v-if=" parseFloat(amount) == 0.00 && friend_end == '0' ">{{currentLang.chooseRechargeType}}</div>
      <div v-else>正在退费流程中</div>
      <div>—-</div>
    </div>
    <div class="flex flex__center mt-52 containerbox">{{show_message}}</div>
    <div class="mlmr">
      <div class="bottomBtn" v-if="parseFloat(amount) <= 0.00 && friend_end == '0'" @click="pay()">点击缴纳{{money}}元押金
      </div>
    </div>
    <div class="bottomBtn" v-if=" parseFloat(amount) >= 0.01 && friend_end == '0'" @click="return_money('1')">
      已通过其他渠道结束单身</div>
    <div class="bottomBtn" v-if=" parseFloat(amount) >= 0.01 && friend_end == '0'" @click="return_money('2')">
      我不想找了，退保证金</div>
    <div class="bottomBtn" v-if=" parseFloat(amount) >= 0.01 && friend_end == '0' " @click="to_pay()">通过此平台找到了</div>
    <div class="bottomBtn" v-if=" parseFloat(amount) >= 0.01 && friend_end == '1' " @click="i_am_ok()">我同意确认关系</div>
    <div class="bottomBtn" v-if=" parseFloat(amount) >= 0.01 && friend_end == '1' " @click="i_cancel()">我不同意 还要继续找</div>
    <div class="bottomBtn" v-if="friend_end == '2' ">请等待对方确认</div>
    <div class="bottomBtn" v-if="parseFloat(amount) >= 0.01 && friend_end == '3'" @click="return_money()">
      退费</div>

    <!--   <div class="bottomBtn" v-if="friend_end == '3'" @click="return_money()">
        女的退费</div> -->
  </div>
</template>

<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  import axios from "axios";
  import qs from "qs";
  import {
    Toast
  } from "vant";
  export default {
    name: "",
    props: {},
    data() {
      return {
        friend_end: '0', //是否有结束交友的申请,如果有,需要去押金页面退钱
        show_message: '', //如果有描述,说的内容
        banknum: '', //银行卡号
        khname: '', //银行名字
        bankname: '', //账户持有人名称
        nowActive: "",
        customizeMoney: "0",
        amount: 0, //会员余额
        money: "0.00", //本次充值的金额
        defaultMoney: { //页面渲染后默认的充值金额

        },
        moneyList: [{
          title: '¥2000',
          money: '2000'
        }],
        languageLib: {
          lang_1: {
            title: 'Rechage',
            amount: 'Amount Remain',
            chooseRechargeType: 'Choose Recharge Way',
            servicesAvailable: 'Services Available',
            upgradeMember: 'Upgrade Membership',
            gift: 'Gift',
            totalAmount: 'Total Amount',
            recharge: 'rechage',
            tip1: 'Choose Amount to Be Deposite',
            tip2: 'This is function test, no drawback, no shipment, no reservation'
          },
          lang_2: {
            title: '缴纳押金',
            amount: '已缴纳押金',
            chooseRechargeType: '请缴纳押金',
            servicesAvailable: '可以享受的服务',
            upgradeMember: '和会员聊天',
            gift: '可看会员资料',
            totalAmount: '总价',
            recharge: '充值',
            tip1: '请选择您要存入的金额',
            tip2: '这是测试功能，测试充值的钱不退款不发货不保留'
          },
          lang_3: {
            title: '缴纳押金',
            amount: '已缴纳押金',
            chooseRechargeType: '请缴纳押金',
            servicesAvailable: '可以享受的服務',
            upgradeMember: '升級會員',
            gift: '禮物',
            totalAmount: '總價',
            recharge: '儲值',
            tip1: '請選擇您要存入的金額',
            tip2: '這是測試功能, 測試充值的錢不退款不發貨不保留'
          },
          currentLang: {

          }
        }
      };
    },
    components: {},
    beforeMount() {
      const language_id = Cookies.get('language_id') || 3; //客户现在要求默认是繁体
      this.currentLang = this.languageLib['lang_' + language_id];

      this.defaultMoney = this.moneyList[0];
      this.money = this.defaultMoney.money;
    },
    mounted() {

      this.getUserInfo(); //加载当前用户信息
      //获取充值列表
      //渲染支付按钮
      const kh = Cookies.get('kh');
      const _that = this;

    },
    methods: {
      pay() {
        var that = this;
        const param = {
          act: 'recharge',
          user: Cookies.get('kh'), //当前支付会员的账号
          sitenum: this.$global.sitenum,
          type: 'friends_cash_pledge', //婚恋押金
          paylx: 'weixinpay',
          je: that.money,
          remark: '婚恋押金',
          openid: Cookies.get('openid'), //当前支付会员的openid
          sblx: 'h5'
        };
        console.log(param);
        const url = this.$global.domain + '/horse/tools/post.ashx';
        axios.post(url, qs.stringify(param))
          .then(res => {
            console.log(res.data);
            if (res.data.status == 1) {
              const orderId = res.data.djh; //订单id
              //跳转网址
              window.location.href = this.$global.domain+ "/horse/WXpay/jsapipay.aspx?type=happyhome&openid=" +
                Cookies.get(
                  'openid') + "&djh=" + orderId + "&sitenum=" + that.$global.sitenum


              // window.location.href = "http://localhost:46277/WXpay/jsapipay.aspx?openid=" + Cookies.get(
              //   'openid') + "&djh=" + orderId;

            }
          });
      },
      back(where) {
        if (where == "main") {
          this.$router.push({
            name: "main",
          });
        } else {
          this.$router.back()
        }

      },
      to_pay() {
        //通过渠道找到对象了,要给钱了
        this.$router.push({
          name: "marry",
        });
      },
      i_am_ok() {
        //确认交易,并且直接双方都退钱
        this.set_status('1');

      },
      i_cancel() {
        //我不同意 关闭订单
        this.set_status('0');

      },
      async set_status(state) {
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/post_job.ashx";
        var param = {
          act: "set_status",
          user: Cookies.get("kh"), //确认人
          state: state,
          type: 'firend',
          // message: this.$refs.message.value, //赠言
          // receiveGiftUserCode: this.userCode, //查询指定会员的时候使用，必须是权限允许的会员
          sitenum: '18777777777',
          token: Cookies.get("token"),
          code: Cookies.get("code"),
        };
        var result = await RequestApi.post(uri, param);
        //console.log(result);
        if (result.status == "1") {
          Toast(result.rsg);
          //延时跳转 让顾客看清提示
          setTimeout(() => {
            //需要延迟的代码 :1秒后延迟跳转到首页，可以加提示什么的
            //this.$router.push("home");
            this.getUserInfo(); //加载当前用户信息
            //延迟时间：1秒
          }, 1000)
          //延时跳转 让顾客看清提示
          // setTimeout(() => {
          //   //需要延迟的代码 :1秒后延迟后退2步，第一步是选择礼品的，第二步才是用户发起界面
          //   if (this.deviceType == "pc") {
          //     //如果是pc，那么就是电脑用，千万不要退两步，退多了聊天页面都没了
          //     this.$router.back(); //后退+刷新；
          //   } else {
          //     this.$router.go(-2); //后退+刷新；
          //   }
          //   //延迟时间：3秒
          // }, 1000)

        } else {
          Toast(result.rsg);
        }
      },
      async return_money(type) {
        let remarks = "";
        if (type == 1) {
          remarks = "其他渠道";
        } else if (type == 2) {
          remarks = "我不想找";
        } else {
          remarks = "本渠道找到的";
        }
        //发起退款申请,也就是提现,如果有钱,就加入到审核内,后台审核后给她提现
        var that = this;
        //重写，改为原路退回

        const param = {
          act: "refund_money",
          hykh: Cookies.get('kh'), //当前支付会员的账号
          sitenum: this.$global.sitenum,
          remarks: remarks,
          token: Cookies.get('token'),
          openid: Cookies.get('openid'), //当前支付会员的openid
        };
        console.log(param);
        const url = this.$global.domain + '/tools/post_job.ashx';
        var result = await RequestApi.post(url, param);
        console.log(result);
        if (result.status == "1") {
          Toast(result.msg);
          //延时跳转 让顾客看清提示
          setTimeout(() => {
            //需要延迟的代码 :1秒后延迟跳转到首页，可以加提示什么的
            //this.$router.push("home");
            this.getUserInfo(); //加载当前用户信息
            //延迟时间：1秒
          }, 1000)

        } else {
          Toast(result.msg);
        }

        // const param = {
        //   action: "application",
        //   hykh: Cookies.get('kh'), //当前支付会员的账号
        //   sitenum: this.$global.sitenum,
        //   itype: '转账到银行卡', //直接存到钱包   转账到银行卡
        //   txje: that.amount,
        //   otype: '账户余额',
        //   remark: remarks, //婚恋押金
        //   token: Cookies.get('token'),
        //   openid: Cookies.get('openid'), //当前支付会员的openid
        //   sblx: 'h5',
        //   code: '',
        //   img: '',
        //   bankCardId: '0,' + that.khname + ',0,0,0,0,' + that.bankname + ',,' + that.banknum +
        //     ',888888888888888' //选择的银行卡
        // };
        // console.log(param);
        // const url = this.$global.domain + '/tools/post_2.ashx?action=application';
        // var result = await RequestApi.post(url, param);
        // console.log(result);
        // if (result.status == "1") {
        //   Toast(result.msg);
        //   //延时跳转 让顾客看清提示
        //   setTimeout(() => {
        //     //需要延迟的代码 :1秒后延迟跳转到首页，可以加提示什么的
        //     //this.$router.push("home");
        //     this.getUserInfo(); //加载当前用户信息
        //     //延迟时间：1秒
        //   }, 1000)

        // } else {
        //   Toast(result.msg);
        // }


      },
      async getUserInfo() {
        //获取用户信息，如果没登录，则跳转到登录
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "getUserInfo",
          user: Cookies.get("kh"),
          token: Cookies.get("token"),
          sitenum: '18777777777'
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          //that.userName = result.name;
          that.amount = result.kje; //会员的账户余额
          that.userGrade = result.fklx;
          that.banknum = result.banknum; //银行卡号
          that.khname = result.khname; //银行名字
          that.bankname = result.bankname; //账户持有人名称
          that.friend_end = result.friend_end; //是否有结束交友的申请,如果有,需要去押金页面退钱
          that.show_message = result.show_message; //结束交易的描述
          if (result.sex == '保密') {
            //没有设置性别,就必须先设置了才允许
            Toast("请您先设置性别后,再缴纳押金");
            //延时跳转 让顾客看清提示
            setTimeout(() => {
              //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
              this.$router.push("setting");
              //延迟时间：3秒
            }, 500)
          }
          if (result.sex == "女") {
            //押金缴纳 男的2000 女的1000
            that.money = "1000";
          } else {
            that.money = "2000"
          }

        } else {
          this.$router.push("login");
        }
      },
      selectMoney(item, i) {
        // 给选中的 金额 加上点击效果
        this.nowActive = i;
        //用户选择要充值的金额
        this.money = item.money; //套餐价，一口价
        this.defaultMoney = item;
      },
      // async topUp() {
      //   if (this.money <= 0) {
      //     Toast(this.currentLang.tip1);
      //     return;
      //   }

      //   // Toast(this.currentLang.tip2);

      //   //存钱接口
      //   var that = this;
      //   var uri = "";
      //   uri = this.$global.domain + "/tools/MallSelect.ashx";
      //   var param = {
      //     action: "recharge2021",
      //     je: this.money,
      //     user: Cookies.get("kh"),
      //     sitenum: '18777777777',
      //     token: Cookies.get("token"),
      //     code: Cookies.get("code"),
      //   };
      //   // console.log("=============================>")
      //   var result = await RequestApi.post(uri, param);
      //   console.log(result);
      //   if (result.status == "1") {
      //     Toast(result.rsg);
      //     //延时跳转 让顾客看清提示
      //     setTimeout(() => {
      //       //需要延迟的代码 :秒后刷新，页面重新加载最新的余额
      //       location.reload();
      //       //延迟时间：秒
      //     }, 1000)
      //   } else {
      //     Toast(result.rsg);
      //   }
      // },
      gift() {
        //礼物
        this.$router.push({
          path: '/gift',
          query: {

          }
        });
      },
      upGrade() {
        //升级
        this.$router.push({
          path: '/upgrade',
          query: {

          }
        });
      }, //

    },
    computed: {},
    watch: {},
  };
</script>

<style scoped lang="less">
  .mlmr {
    margin-right: 15px;
    margin-left: 15px;
  }

  .container {
    width: 100%;
    height: 156px;
    background: -webkit-linear-gradient(left, #ff936e, #ff5d3e);
    color: #fff;
  }

  .containerbox {
    color: #e62f39;
  }

  .box2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .box3 {
    background: #fff;
  }

  .mBox {
    width: 152px;
    height: 52px;
    border: 1px solid #999;
    border-radius: 30px;
  }

  .fixed {
    width: 100%;
    position: fixed;
    bottom: 0px;
  }

  .tall {
    height: 62px;
    line-height: 62px;
    color: #000;
  }

  .btn {
    width: 250px;
    height: 130px;
    line-height: 62px;
    color: #fff;
    font-size: 22px;
    text-align: center;
    // width: 136px;
    // height: 62px;
    // line-height: 62px;
    // color: #fff;
    // font-size: 22px;
    // background: #ff4a53;
    // text-align: center;
  }

  .vipMain {
    min-height: 300px;
    padding-bottom: 120px;
    padding-top: 40px;
  }

  .backHome {
    height: 38px;
    width: 100%;
    line-height: 38px;
    position: fixed;
    background-color: white;
    box-shadow: 2px 2px 6px #bfb8b8;
    display: inline-block;
    top: 0px;
    text-align: center;
    font-size: 16px;
    color: #222;
    z-index: 999;

    span {
      position: absolute;
      // border: 1px red dashed;
    }

    .backIco {
      left: 15px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/back2.png");
    }

    .backHomeIco {
      left: 43px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/home.png");
    }


  }

  .touchActive {
    color: #fff;
    background: #ff5d3e;
    border: 1px solid #ff5d3e;

    div {
      color: #fff;

    }

    input {
      background-color: transparent;
      border-color: white !important;
    }
  }

  .bottomBtn {
    position: relative;
    margin: 5px auto 5px auto;
    width: 354px;
    height: 54px;
    line-height: 54px;
    border-radius: 40px;
    background-color: #ea2e3a;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
</style>
